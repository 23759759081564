import {createAction} from 'redux-act';

import type {InitialState} from './reducers';

export const actions = {
  downloadCSV: createAction('panels/DOWNLOAD_CSV_REQUESTED'),
  fetchData: createAction('panels/FETCH_DATA'),
  fetchDataSuccess: createAction<{data: InitialState['data']; total: InitialState['total']}>(
    'panels/FETCH_DATA_SUCCESS',
  ),
  fetchDataFail: createAction('panels/FETCH_DATA_FAIL'),

  setPageSort: createAction<InitialState['pagination']['sort']>('panels/SET_SORT'),
  setPageLimit: createAction<InitialState['pagination']['limit']>('panels/SET_PAGE_LIMIT'),
  setPageSkip: createAction<InitialState['pagination']['skip']>('panels/SET_PAGE_SKIP'),
  setFilters: createAction<InitialState['filters']>('panels/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('panels/CONCAT_FILTERS'),
  resetFilters: createAction('panels/RESET_FILTERS'),
  resetPagination: createAction('panels/RESET_PAGINATION'),
};
