import {StationSortOption} from '__generated__/globalTypes';
import {createReducer} from 'redux-act';

import {actions} from './actions';

export const initialState = {
  pagination: {
    skip: 0,
    limit: 50,
    sort: StationSortOption.AGENCY_NAME_ASC,
  },
  filters: {
    query: null as string,
    name: null as string,
    agency: null as string[] | null,
    station: null as string[] | null,
    services: null as string[] | null,
    platforms: null as string[] | null,
    routes: null as string[] | null,
    connectingStations: null as string[] | null,
    market: null as string[] | null,
  },
};

export type Filters = typeof initialState.filters;

export type InitialState = typeof initialState;

export default createReducer<InitialState>({}, initialState)
  .on(actions.resetPagination, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
  }))
  .on(actions.setPageSort, (state, sort) => ({
    ...state,
    pagination: {
      ...state.pagination,
      sort,
      skip: initialState.pagination.skip,
    },
  }))
  .on(actions.setPageLimit, (state, limit) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: 0,
      limit,
    },
  }))
  .on(actions.setPageSkip, (state, skip) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip,
    },
  }))
  .on(actions.resetFilters, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters: initialState.filters,
  }))
  .on(actions.setFilters, (state, filters) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters: filters as Filters,
  }))
  .on(actions.concatFilters, (state, filters) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  }));
