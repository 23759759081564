import type {History} from 'history';

import {all, put} from 'redux-saga/effects';

import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';

import type {Filters} from '../reducers';

export default function* init(history: History<unknown>) {
  yield all([
    put(actions.resetPagination()),
    querySync('$limit', {
      history,
      selector: ({stations: {pagination}}) => pagination.limit,
      action: actions.setPageLimit,
      stringToValue: parseInt,
    }),
    querySync('$skip', {
      history,
      selector: ({stations: {pagination}}) => pagination.skip,
      action: actions.setPageSkip,
      stringToValue: parseInt,
      listeners: [actions.setFilters, actions.resetFilters],
    }),
    querySync('$sort', {
      history,
      selector: ({stations: {pagination}}) => pagination.sort,
      action: actions.setPageSort,
    }),
    querySyncItemFilter('name'),
    querySyncItemFilter('query'),
    querySyncListFilters('services'),
    querySyncListFilters('agency'),
    querySyncListFilters('routes'),
    querySyncListFilters('platforms'),
    querySyncListFilters('connectingStations'),
    querySyncListFilters('station'),
  ]);

  type ListFields = Pick<Filters, keyof Omit<Filters, 'name' | 'query'>>;
  function querySyncListFilters(field: keyof ListFields) {
    return querySync(`$${field}`, {
      history,
      selector: ({stations: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (filter) => ({
        [field]: filter?.split('|') ?? null,
      }),
      valueToString: (filters) => filters[field]?.join('|') ?? '',
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    });
  }

  type ItemFields = Pick<Filters, 'name' | 'query'>;
  function querySyncItemFilter(field: keyof ItemFields) {
    return querySync(`$${field}`, {
      history,
      selector: ({stations: {filters}}) => filters,
      action: actions.concatFilters,
      stringToValue: (s) => ({
        [field]: stringTransforms.stringToValue(s),
      }),
      valueToString: (v) => stringTransforms.valueToString(v[field]),
      listeners: [actions.setFilters, actions.resetFilters],
      defaultValue: {},
    });
  }
}
