import isNil from 'lodash/isNil';

type SortingType = 'asc' | 'desc';
export type Sorting<T extends object> = {[index in keyof T]: SortingType};

export type SortingKeys<T extends string> = {[index in T]?: SortingType};

const toArray = <TModel extends Object>(sort: Sorting<TModel>): string[] => {
  const propKeys = Object.keys(sort || {});

  return propKeys
    .filter((key) => !isNil(sort[key]))
    .reduce((sortArray, key) => [...sortArray, `${key}:${sort[key]}`], []);
};

export const SortingUtil = {
  toArray,
};
