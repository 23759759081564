import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Autocomplete,
  AutocompleteSize,
  FilterActionPopper,
  PopperContentSection,
} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import {useMediaCategories, useOpen} from 'hooks';
import {useScrollListToBottom} from 'hooks/useScrollListToBottom';
import _isEmpty from 'lodash/isEmpty';

import SelectedNamesList from './List';
import {styles} from './styles';

import type {AutocompleteProps, onCloseType} from '@onsmart/ui-kit';
import type {MediaFilter} from 'models/MediaType';
import type {Styles} from './styles';
const getOptionLabel = ({text}: {text: string}) => text;
const toOption = (value: string) => (!!value ? {value, text: value} : null);

const useSelectCategory = (
  category: string,
  onSelectChange: (selected: Partial<MediaFilter>) => void,
) => {
  const selectedCategoryOption = React.useMemo(() => toOption(category), [category]);

  const onCategoryChange = React.useCallback(
    (op: AutocompleteProps['options'][number]) => {
      if (op && category === op.value) return;

      onSelectChange(op ? {category: op.value} : {});
    },
    [category, onSelectChange],
  );

  return {selectedCategoryOption, onCategoryChange};
};

const useSelectSubCategory = (
  category: string,
  subCategory: string,
  onSelectChange: (selected: Partial<MediaFilter>) => void,
) => {
  const selectedSubCategoryOption = React.useMemo(() => toOption(subCategory), [subCategory]);

  const onSubcategoryChange = React.useCallback(
    (op: AutocompleteProps['options'][number]) => {
      if (op && subCategory === op.value) return;
      onSelectChange(
        Object.assign({}, category && {category}, op && op.value && {subCategory: op.value}),
      );
    },
    [category, subCategory, onSelectChange],
  );

  return {selectedSubCategoryOption, onSubcategoryChange};
};

const useNamesList = (
  selected: Partial<MediaFilter>,
  onSelectChange: (selected: Partial<MediaFilter>) => void,
) => {
  const onNamesChange = React.useCallback(
    (names: string[]) => {
      onSelectChange({...selected, names});
    },
    [selected, onSelectChange],
  );

  return {onNamesChange};
};

const useMediaNameFilterPopper = (props: Pick<Props, 'value' | 'onChange' | 'onPopperClose'>) => {
  const {value, onChange, onPopperClose} = props;

  const [selected, setSelected] = React.useState<Partial<typeof props.value>>(props.value);
  const [isMenuOpen, onMenuOpen, onMenuClose] = useOpen();

  const {isLoading, categoriesOptions, subcategoriesOptions} = useMediaCategories(
    selected?.category,
  );

  const isSelectedEmpty = React.useMemo(
    () =>
      _isEmpty(selected?.category) && _isEmpty(selected?.subCategory) && _isEmpty(selected?.names),
    [selected],
  );

  const onClose = React.useCallback(
    (evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>, type?: onCloseType) => {
      if (type === 'away' && isMenuOpen) {
        return;
      }

      if (!equal(selected, value)) {
        onPopperClose(selected);
      } else {
        onPopperClose();
      }
    },
    [isMenuOpen, value, selected, onPopperClose],
  );

  const onSubmit = React.useCallback(
    (ev: any) => {
      onChange(selected, ev);
    },
    [selected, onChange],
  );

  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  return {
    selected,
    isLoading,
    isSelectedEmpty,
    categoriesOptions,
    subcategoriesOptions,
    onMenuOpen,
    onMenuClose,
    onSubmit,
    onClose,
    setSelected,
  };
};

interface Props extends Styles {
  value: MediaFilter;
  onPopperClose: (temp?: Partial<MediaFilter>) => void;
  onChange: (media: Partial<MediaFilter>, ev?: React.SyntheticEvent) => void;
  open: boolean;
  hasChanges: boolean;
  resetTempValue: () => void;
  anchorEl: HTMLElement;
}

const MediaTypeFilterPopper = (props: Props) => {
  const {open, anchorEl, value, hasChanges, resetTempValue, onChange, onPopperClose} = props;

  const {
    selected,
    isLoading,
    isSelectedEmpty,
    categoriesOptions,
    subcategoriesOptions,
    onMenuOpen,
    onMenuClose,
    onSubmit,
    onClose,
    setSelected,
  } = useMediaNameFilterPopper({value, onChange, onPopperClose});

  const filterPopperRef = useScrollListToBottom(selected?.names);
  const {selectedCategoryOption, onCategoryChange} = useSelectCategory(
    selected?.category,
    setSelected,
  );
  const {selectedSubCategoryOption, onSubcategoryChange} = useSelectSubCategory(
    selected?.category,
    selected?.subCategory,
    setSelected,
  );

  const {onNamesChange} = useNamesList(selected, setSelected);

  return (
    <FilterActionPopper
      innerRef={filterPopperRef}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      headerTitle="Media Type"
      hasChanges={hasChanges}
      onCancelLabel="Reset"
      onCancel={hasChanges && resetTempValue}
      disabled={isSelectedEmpty}
      onSubmit={onSubmit}
    >
      <PopperContentSection>
        <Autocomplete
          autoFocus
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          label="Category"
          size={AutocompleteSize.small}
          options={categoriesOptions}
          getOptionLabel={getOptionLabel}
          onChange={onCategoryChange}
          value={selectedCategoryOption}
          placeholder=""
          isClearable
          isLoading={isLoading}
        />
      </PopperContentSection>
      <PopperContentSection>
        <Autocomplete
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          label="Subcategory"
          size={AutocompleteSize.small}
          options={subcategoriesOptions}
          getOptionLabel={getOptionLabel}
          onChange={onSubcategoryChange}
          value={selectedSubCategoryOption}
          placeholder=""
          isClearable
        />
      </PopperContentSection>
      <SelectedNamesList
        selected={selected}
        onChange={onNamesChange}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </FilterActionPopper>
  );
};

export default withStyles(styles)(MediaTypeFilterPopper);
