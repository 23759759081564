import {environment} from 'config/environment';
import {RestService} from 'services/RestService';

export class FilterQueryRestService extends RestService<unknown> {
  private static instance?: FilterQueryRestService;

  private constructor() {
    super({
      baseUrl: environment.settings.apiUrl.queryApi
    });
  }

  static getInstance(): FilterQueryRestService {
    if (!FilterQueryRestService.instance) {
      FilterQueryRestService.instance = new FilterQueryRestService();
    }
    
    return FilterQueryRestService.instance;
  }

  public queryCSV(ids: string[], madFields: string[]) {
    return this.axiosInstance
      .post<any>(
        '/query',
        {
          select: {mad: madFields},
          sort: null,
          where: {mad: {must: {id: {terms: ids}}}},
        },
        {params: {format: 'csv'}},
      )
      .then((r) => r.data);
  }
}
