import type {FilterColorChipProps} from '@onsmart/ui-kit';
import {FilterColorChip, filterColorChipStylesDecorator} from '@onsmart/ui-kit';

import {truncateArray} from 'utils/string';

interface Props extends FilterColorChipProps<string[]> {}

class Chip extends FilterColorChip<Props, string[]> {
  getLabel(mediaTypes: string[]) {
    const truncateSize = 1;
    const leftFilters = mediaTypes.length - truncateSize;
    const truncate = (leftFilters > 1 ? truncateArray(mediaTypes, truncateSize) : mediaTypes).join(
      ', ',
    );

    if (leftFilters > 1) {
      return `Media Type: ${truncate} and ${leftFilters} more`;
    } else {
      return `Media Type: ${truncate}`;
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
