import {createAction} from 'redux-act';

import type {InitialState} from './reducers';

export const actions = {
  setPageSort: createAction<InitialState['pagination']['sort']>('stations/SET_SORT'),
  setPageLimit: createAction<InitialState['pagination']['limit']>('stations/SET_PAGE_LIMIT'),
  setPageSkip: createAction<InitialState['pagination']['skip']>('stations/SET_PAGE_SKIP'),
  setFilters: createAction<Partial<InitialState['filters']>>('stations/SET_FILTERS'),
  concatFilters: createAction<Partial<InitialState['filters']>>('stations/CONCAT_FILTERS'),
  resetFilters: createAction('stations/RESET_FILTERS'),
  resetPagination: createAction('stations/RESET_PAGINATION'),
};
