import _get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import * as PanelsActivityService from 'services/graphql/PanelsActivityService';
import {PanelCurationEventType} from '__generated__/globalTypes';

const STORAGE_KEY = 'activity-queue-offline';
const summaryMessages = {
  curation: (data) =>
    `Curation status was changed from ${kebabCase(data.o)} to ${kebabCase(data.n)}`,
  streetview: () => 'Street view snapshots captured',
  location: () => 'Location data changed',
};

export type TopicType = 'curation' | 'streetview' | 'location';
export type ActivityType = 'Update' | 'Create' | 'Delete';
export type ActivityData = string[] | {o: string; n: string};
export type ActivityModel = {
  id: string;
  name: string;
  type: string;
  locationUrl?: string;
};
export type ActivityInput = {
  data: ActivityData;
  model: ActivityModel;
};

type Storage = {[index: string]: any};

export class ActivityQueue {
  storage: Storage = {};
  private static instance: ActivityQueue;

  private constructor() {
    setTimeout(() => this.processOffline(), 1000);
    this.initEvent();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ActivityQueue();
    }
    return this.instance;
  }

  async processOffline() {
    let item = localStorage.getItem(STORAGE_KEY);
    if (!item) return Promise.resolve();

    let storage = JSON.parse(item);
    await Promise.all(
      Object.keys(storage).map((k) => this.createActivity(k, storage[k], 'Update')),
    );

    localStorage.removeItem(STORAGE_KEY);
  }

  initEvent() {
    window.addEventListener(
      'storage',
      (e) => {
        let isEnqueueEvent = e.key === STORAGE_KEY && e.newValue;
        if (isEnqueueEvent) {
          let tabId = Math.random().toString();

          localStorage.setItem(`${STORAGE_KEY}-key`, tabId);
          setTimeout(() => {
            let storageKey = localStorage.getItem(`${STORAGE_KEY}-key`);

            if (storageKey === tabId) {
              localStorage.removeItem(`${STORAGE_KEY}-key`);
              setTimeout(() => this.processOffline());
            }
          }, 1000);
        }
      },
      false,
    );

    window.addEventListener(
      'beforeunload',
      (_) => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.storage));
      },
      false,
    );
  }

  combine(topic: string, oldData: ActivityInput, newData: ActivityInput) {
    return {
      model: newData.model,
      data:
        topic === 'curation'
          ? //concat the first old with the last new curationStastus
            {o: _get(oldData, 'data.o') || _get(newData, 'data.o'), n: _get(newData, 'data.n')}
          : //concat all images url sended
            ((_get(oldData, 'data') as any[]) || []).concat(_get(newData, 'data')),
    };
  }

  queue<T extends ActivityData>(topic: TopicType, data: T, model: any) {
    this.storage[topic] = this.combine(topic, this.storage[topic], {model, data});
  }

  async createActivityByTopic(topic: string, type: ActivityType = 'Update') {
    const storage = this.storage[topic];
    return this.createActivity(topic, storage, type);
  }

  async createActivity(topic: string, input?: any, type: ActivityType = 'Update') {
    const {data, model} = input;
    const summary = summaryMessages[topic](data);

    const image = (Array.isArray(data) ? data : []).map((i) => {
      const url = new URL(i);
      url.searchParams.delete('key');
      return url.href;
    });

    const object = {
      id: model.id,
      type: model.type,
      name: model.name,
      url: model.locationUrl,
    };

    await PanelsActivityService.createActivity({
      type: this.getActivityEventType(type),
      summary,
      image,
      object,
    });

    delete this.storage[topic];
  }

  private getActivityEventType(type: ActivityType): PanelCurationEventType {
    switch (type) {
      case 'Update':
        return PanelCurationEventType.UPDATE;
      case 'Create':
        return PanelCurationEventType.CREATE;
      case 'Delete':
        return PanelCurationEventType.DELETE;
      default:
        return PanelCurationEventType.UPDATE;
    }
  }
}

ActivityQueue.getInstance();
