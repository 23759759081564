import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import {FilterActionPopper, MultiSelectList, PopperContentSection} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import _isEmpty from 'lodash/isEmpty';

import {arrayToIndexed, map, marketToOption, sortBy} from 'utils/array';
import {compose} from 'utils/functional';

import {mapDispatchToProps, mapStateToProps} from './selectors';
import {styles} from './styles';

import type {onCloseType, Option} from '@onsmart/ui-kit';
import type {MapDispatchToProps, MapStateToProps} from './selectors';
import type {Styles} from './styles';

interface MarketFilterPopperInternalProps extends MapStateToProps, MapDispatchToProps, Styles {}

interface MarketFilterPopperState {
  isMenuOpen: boolean;
  selected: string[];
  includeInactiveMarkets: boolean;
}

class MarketFilterPopperComponent extends Component<
  MarketFilterPopperInternalProps,
  MarketFilterPopperState
> {
  filterComponent: any;

  constructor(props: MarketFilterPopperInternalProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      selected: props.value || [],
      includeInactiveMarkets: false,
    };
  }

  handleSubmit = (ev: any) => {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange(selected, ev);
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }

    const {selected} = this.state;
    this.props.onPopperClose(selected);
  };

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleMultiSelectedValueChange = (value: Option[]) => {
    const selected = value.map((m) => !_isEmpty(m) && m.value);
    const sortedSelected = (selected || []).sort();
    this.setState({selected: sortedSelected});
  };

  UNSAFE_componentWillReceiveProps(nextProps: MarketFilterPopperInternalProps) {
    if (!equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  componentDidUpdate(
    prevProps: MarketFilterPopperInternalProps,
    prevState: MarketFilterPopperState,
  ) {
    if (
      this.state.selected !== prevState.selected &&
      this.state.selected.length > prevState.selected.length
    ) {
      if (this.filterComponent && this.filterComponent.scrollbars) {
        this.filterComponent.scrollbars.scrollToBottom(true);
      }
    }
  }

  handleCheckboxChange = () => {
    const {includeInactiveMarkets} = this.state;
    this.setState({includeInactiveMarkets: !includeInactiveMarkets});
  };

  render() {
    const {classes, anchorEl, open, hasChanges, resetTempValue, marketList} = this.props;
    const {selected, includeInactiveMarkets} = this.state;

    const isSelectedEmpty = _isEmpty(selected);
    const options = this.getOptions();

    return (
      <FilterActionPopper
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        hasChanges={hasChanges}
        onClose={this.handleClose}
        headerTitle="Market"
        onCancelLabel="Reset"
        onCancel={hasChanges && resetTempValue}
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
      >
        <MultiSelectList
          label="Select markets"
          values={this.getFilterValues() || []}
          options={options || []}
          autoFocus
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          onChange={this.handleMultiSelectedValueChange}
          classes={{rootWithValues: classes.selectWithValues}}
        />
        {!marketList && (
          <PopperContentSection className={classes.includeInactiveMarketsSection} {...({} as any)}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={this.handleCheckboxChange}
                  checked={includeInactiveMarkets}
                />
              }
              label="Inactive markets"
              classes={{label: classes.checkbox}}
            />
          </PopperContentSection>
        )}
      </FilterActionPopper>
    );
  }

  getOptions = () => {
    const {markets} = this.props;
    const {selected, includeInactiveMarkets} = this.state;

    const filterByValue = (markets: MarketFilterPopperInternalProps['markets']) => {
      const result = markets
        .filter((m) => {
          const has = selected.find((el) => el.includes(m.id)) ? true : false;
          return !has;
        })
        .filter((m) => (includeInactiveMarkets ? true : m.active));
      return result;
    };

    const filteredResults = filterByValue(markets);

    return compose(map(marketToOption), sortBy('name'))(filteredResults);
  };

  getFilterValues = () => {
    const {markets} = this.props;
    const {selected} = this.state;

    if (!markets || markets.length === 0) {
      return null;
    }
    const indexedMarkets = arrayToIndexed(markets, 'id');
    return selected.reduce((acc, marketCode) => {
      const market = indexedMarkets[marketCode];
      if (market) {
        return [...acc, marketToOption(market)];
      }
      return acc;
    }, []);
  };
}

const MarketFilterPopper = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MarketFilterPopperComponent),
);

export default MarketFilterPopper;
