import type {History} from 'history';
import _isEmpty from 'lodash/isEmpty';

import {LocationTypeWithoutText} from '../../../../../models/Location';
import {querySync, stringTransforms} from '../../prism/lib';
import {actions} from '../actions';
import {join, split, UrlParams} from './';

import type {Filters} from '../reducers';
const stringToValue = (stringChunk?: string) => {
  if (!stringChunk) return {location: null};

  const [type, values] = split(stringChunk, '^');

  return {
    location: {
      type,
      typeKey: LocationTypeWithoutText[type],
      values: split(values),
    } as Filters['location'],
  };
};

const valueToString = (value: Partial<Pick<Filters, 'location'>>) => {
  if (_isEmpty(value.location)) return '';

  return [
    stringTransforms.valueToString(value.location?.type || ''),
    join(value.location?.values || []),
  ].join('^');
};

export const locationQuerySync = (history: History<unknown>) =>
  querySync(UrlParams.location, {
    history,
    selector: ({panels: {filters}}) => filters,
    action: actions.concatFilters,
    stringToValue,
    valueToString,
    listeners: [actions.setFilters, actions.resetFilters],
    defaultValue: {},
  });
