import produce from 'immer';
import {clipGeoJSON} from 'utils/map/clipRoads';

import type {RoadPanel} from 'models/RoadPanel';

/**
 * Help us to write a reducer for a single property of State
 *
 * @param prop - the name of a initialState property
 * @param fn - a function to fill a given property from initialState
 * @returns - a reducer function
 */
export const produceFor = <
  State,
  Arg extends any,
  P extends keyof State,
  Fn extends (s: State[P], arg: Arg) => State[P],
>(
  prop: P,
  fn: Fn,
) => {
  return produce((draft: State, payload: Arg) => {
    draft[prop] = fn(draft[prop], payload);
  }) as (state: State, arg: Arg) => State;
};

interface IPanelProps {
  id: string;
  location: {
    center: Pick<GeoJSON.Point, 'coordinates'>;
  };
}

export function getClipData(panel: IPanelProps, rawData: RoadPanel): RoadPanel {
  const [lon, lat] = panel.location.center.coordinates;
  const features = rawData.data.features.map((item) =>
    item.geometry.type === 'MultiPolygon'
      ? ({
          ...item,
          geometry: {coordinates: item.geometry.coordinates[0], type: 'Polygon'},
        } as typeof item)
      : item,
  );

  return {
    id: panel.id,
    data: clipGeoJSON(features),
    center: {lat, lon},
    updatedAt: '',
  };
}
