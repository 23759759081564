import {routerReducer} from 'react-router-redux';
import {authorization} from '@onsmart/auth-client';
import {combineReducers} from 'redux';

import {app} from './global/reducers';
import activities from './modules/activities';
import assetForm from './modules/assetForm';
import inventory from './modules/inventory/reducers';
import markets from './modules/markets/reducers';
import panelForm from './modules/panelForm';
import panels from './modules/panels/reducers';
import quintileDataSets from './modules/quintileDataSets/reducers';
import showings from './modules/showings/reducers';
import stations from './modules/stations/reducers';

const rootObjReducer = {
  app,
  panelForm,
  assetForm,
  inventory,
  panels,
  activities,
  markets,
  showings,
  stations,
  quintileDataSets,
  router: routerReducer,
  ...authorization.reducer,
};

type RootType = typeof rootObjReducer;

export type RootState = {readonly [P in keyof RootType]: ReturnType<RootType[P]>};

export const getState = <T>(fn: (s: RootState) => T) => fn;

export const rootReducer = combineReducers<RootState>(rootObjReducer);
