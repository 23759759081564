import * as React from 'react';
import {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {FilterActionPopper, MultiSelectList} from '@onsmart/ui-kit';
import equal from 'fast-deep-equal';
import isEmpty from 'lodash/isEmpty';

import {styles} from './styles';

import type {onCloseType, Option} from '@onsmart/ui-kit';
import type {Styles} from './styles';

interface Props {
  value: string[];
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
  onChange: (markets: string[], ev?: React.SyntheticEvent) => void;
  onPopperClose: (temp?: string[]) => void;
  options: Option[];
  headerTitle: string;
  label: string;
}

interface InternalProps extends Props, Styles {}

interface PopperState {
  isMenuOpen: boolean;
  selected: string[];
}

class PopperComponent extends Component<InternalProps, PopperState> {
  filterComponent: any;

  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isMenuOpen: false,
      selected: props.value || [],
    };
  }

  handleSubmit = (ev: any) => {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange(selected, ev);
  };

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    if (type === 'away' && this.state.isMenuOpen) {
      return;
    }

    const {selected} = this.state;
    this.props.onPopperClose(selected);
  };

  handleMenuOpen = () => {
    this.setState({isMenuOpen: true});
  };

  handleMenuClose = () => {
    this.setState({isMenuOpen: false});
  };

  handleMultiSelectedValueChange = (value: Option[]) => {
    const selected = value.map((m) => !isEmpty(m) && m.value);
    const sortedSelected = (selected || []).sort();
    this.setState({selected: sortedSelected});
  };

  UNSAFE_componentWillReceiveProps(nextProps: InternalProps) {
    if (!equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  componentDidUpdate(prevProps: InternalProps, prevState: PopperState) {
    if (
      this.state.selected !== prevState.selected &&
      this.state.selected.length > prevState.selected.length
    ) {
      if (this.filterComponent && this.filterComponent.scrollbars) {
        this.filterComponent.scrollbars.scrollToBottom(true);
      }
    }
  }

  render() {
    const {classes, anchorEl, open, hasChanges, resetTempValue} = this.props;
    const {selected} = this.state;
    const isSelectedEmpty = isEmpty(selected);
    const [unselectedOptions, selectedOptions] = this.optionPartitions();

    return (
      <FilterActionPopper
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        hasChanges={hasChanges}
        onClose={this.handleClose}
        headerTitle={this.props.headerTitle}
        onCancelLabel="Reset"
        onCancel={hasChanges && resetTempValue}
        disabled={isSelectedEmpty}
        onSubmit={this.handleSubmit}
      >
        <MultiSelectList
          label={this.props.label}
          values={selectedOptions}
          options={unselectedOptions}
          autoFocus
          onMenuOpen={this.handleMenuOpen}
          onMenuClose={this.handleMenuClose}
          onChange={this.handleMultiSelectedValueChange}
          classes={{rootWithValues: classes.selectWithValues}}
        />
      </FilterActionPopper>
    );
  }

  optionPartitions = () => {
    const {options = []} = this.props;
    const {selected} = this.state;

    const selectedSet = new Set(selected);
    return options.reduce(
      (acc, option) => {
        acc[+selectedSet.has(option.value)].push(option);
        return acc;
      },
      [[], []],
    );
  };
}

const MultiSelectPopper = withStyles(styles)(PopperComponent);

export default MultiSelectPopper;
