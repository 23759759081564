import * as React from 'react';
import {BaseFilter} from '@onsmart/ui-kit';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import Chip from './Chip';
import {Popper} from './Popper';

import type {BaseFilterProps} from '@onsmart/ui-kit';
export type Props = BaseFilterProps<string[]> & {
  loadSuggestions: (search: string) => Promise<string[]>;
};
const FILTER_ID = 'inventory' as const;
class InventoryIdFilter extends BaseFilter<Props, string[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Inventory ID',
    description: 'Filter by unit number or ID.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: InventoryIdFilter.defaultFilterAddOption,
  } as Partial<Props>;

  handleSetTempValue = (value: string[]) => {
    this.context.handleSetTempValue(FILTER_ID, value);
  };

  render() {
    const {value, initialValue, isOpen, context, tempValue, resetTempValue} = this;
    const {loadSuggestions} = this.props;
    const {anchorEl, disabled} = context;

    const isLoaded = !disabled;

    return (
      <div>
        {!_isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={isLoaded}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!_isEqual(value, initialValue)}
          />
        )}

        {isOpen && (
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            resetTempValue={resetTempValue}
            loadSuggestions={loadSuggestions}
          />
        )}
      </div>
    );
  }
}

export default InventoryIdFilter;
