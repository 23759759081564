import type {FunctionComponent} from 'react';
import React from 'react';
import {filterColorChipStylesDecorator, MultipleCheckFilterChip} from '@onsmart/ui-kit';

import type {MultipleCheckFilterChipProps} from '@onsmart/ui-kit';
interface Props extends MultipleCheckFilterChipProps {}

const Chip: FunctionComponent<Props> = (props) => {
  return <MultipleCheckFilterChip {...props} />;
};

Chip.defaultProps = {
  chipPrefix: 'Visibility:',
};

export default filterColorChipStylesDecorator(Chip);
