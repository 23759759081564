import * as React from 'react';
import {BaseFilter} from '@onsmart/ui-kit';
import isEqual from 'lodash/isEqual';

import {generalToOption} from 'utils/array';

import Chip from '../../../../../common/filters/BasicChip/Chip';
import MultiSelectPopper from '../../../../../common/filters/MultiSelectPopper/Popper';

import type {BaseFilterProps} from '@onsmart/ui-kit';
import type {SearchShowingsPackageTypes} from 'components/pages/Showings/hooks/__generated__/SearchShowingsPackageTypes';
type Packages = SearchShowingsPackageTypes['searchShowingsPackageTypes'];

type Props = BaseFilterProps<string[]> & {
  packages?: Packages;
};
const FILTER_ID = 'package' as const;

class ShowingsPackageFilter extends BaseFilter<Props, string[]> {
  static id: typeof FILTER_ID = FILTER_ID;

  static defaultProps: Partial<Props> = {
    ...BaseFilter.defaultProps,
    defaultValue: [],
    filterAddOption: {
      id: FILTER_ID,
      title: 'Package',
      description: 'Filter by packages',
    },
  };

  toOption(list: Packages) {
    return list.map((entry) => generalToOption(entry));
  }

  render() {
    const {disabled, anchorEl} = this.context;
    const {value, initialValue, isOpen, resetTempValue, tempValue} = this;

    return (
      <div>
        {value.length > 0 && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
            labelPrefix="Package"
          />
        )}
        {isOpen && (
          <MultiSelectPopper
            open={isOpen}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            resetTempValue={resetTempValue}
            options={this.toOption(this.props.packages)}
            headerTitle={'Package'}
            label={'Select Package'}
          />
        )}
      </div>
    );
  }
}

export default ShowingsPackageFilter;
