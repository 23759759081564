import {SearchTermFilter} from '@onsmart/ui-kit';
import {ShowingsSortOption, ShowingStatus} from '__generated__/globalTypes';
import {createReducer} from 'redux-act';

import DataOriginFilter from 'components/common/filters/DataOriginFilter/DataOriginFilter';
import MarketFilter from 'components/common/filters/MarketFilter/MarketFilter';
import ShowingsCoverageFilter from 'components/pages/Showings/Content/filters/ShowingsCoverageFilter/ShowingsCoverageFilter';
import ShowingsDescriptionFilter from 'components/pages/Showings/Content/filters/ShowingsDescriptionFilter/ShowingsDescriptionFilter';
import ShowingsMediaTypeFilter from 'components/pages/Showings/Content/filters/ShowingsMediaTypeFilter/ShowingsMediaTypeFilter';
import ShowingsNameOnContractFilter from 'components/pages/Showings/Content/filters/ShowingsNameOnContractFilter/ShowingsNameOnContractFilter';
import ShowingsPackageFilter from 'components/pages/Showings/Content/filters/ShowingsPackageFilter/ShowingsPackageFilter';

import {actions} from './actions';

import type {ShowingCurationStatus, ShowingDataOrigin} from '__generated__/globalTypes';
const initialState = {
  pagination: {
    skip: 0,
    limit: 50,
    sort: ShowingsSortOption.SHORT_ID_ASC,
  },
  filters: {
    [SearchTermFilter.id]: null as string,
    status: [ShowingStatus.ACTIVE],
    curationStatus: null as ShowingCurationStatus[],
    [ShowingsCoverageFilter.id]: null as string[],
    [ShowingsDescriptionFilter.id]: null as string[],
    [MarketFilter.id]: null as string[],
    [DataOriginFilter.id]: null as ShowingDataOrigin[],
    [ShowingsMediaTypeFilter.id]: null as string[],
    [ShowingsNameOnContractFilter.id]: null as string[],
    [ShowingsPackageFilter.id]: null as string[],
  },
};

export type Filters = typeof initialState.filters;

export type InitialState = typeof initialState;

export default createReducer<InitialState>({}, initialState)
  .on(actions.resetPagination, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
  }))
  .on(actions.setPageSort, (state, sort) => ({
    ...state,
    pagination: {
      ...state.pagination,
      sort,
      skip: initialState.pagination.skip,
    },
  }))
  .on(actions.setPageLimit, (state, limit) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: 0,
      limit,
    },
  }))
  .on(actions.setPageSkip, (state, skip) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip,
    },
  }))
  .on(actions.resetFilters, (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters: initialState.filters,
  }))
  .on(actions.setFilters, (state, filters) => ({
    ...state,
    pagination: {
      ...state.pagination,
      skip: initialState.pagination.skip,
    },
    filters,
  }))
  .on(actions.concatFilters, (state, filters) => ({
    ...state,
    filters: {
      ...state.filters,
      ...filters,
    },
  }));
