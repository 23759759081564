import type {CurationStatus} from '__generated__/globalTypes';
import {PanelStatus} from '__generated__/globalTypes';

import {aggsMediaNames} from 'utils/models/markets';

import {CoreClient, gql} from './graphql/CoreClient';

import type {UnPromisify} from 'utils/promise';

import type {
  GetAllMarketsWithPanelsSummary,
  GetAllMarketsWithPanelsSummaryVariables,
} from './__generated__/GetAllMarketsWithPanelsSummary';

import type {SearchMarkets, SearchMarketsVariables} from './__generated__/SearchMarkets';
const SEARCH_MARKETS = gql`
  query SearchMarkets($sort: MarketsSortOption = NAME_ASC, $limit: Int, $query: String) {
    searchMarkets(sort: [$sort], limit: $limit, query: $query) {
      total
      data {
        id
        name
        code
        active
        transitAgency
      }
    }
  }
`;

const GET_ALL_MARKETS_WITH_PANELS_SUMMARY = gql`
  query GetAllMarketsWithPanelsSummary($panelStatus: [PanelStatus!], $mediaNames: [String!]) {
    searchMarkets(limit: 200) {
      total
      data {
        id
        name
        code
        active
        panelsSummary(panelStatus: $panelStatus, mediaNames: $mediaNames) {
          withPhotos
          withoutPhotos
          withoutRoads
          panelStatus {
            grossTotal
            type
            curationStatus {
              type
              total
            }
          }
        }
      }
    }
  }
`;

function getAllMarketsWithPanelSummary(args: GetAllMarketsWithPanelsSummaryVariables) {
  const options = {
    cache: {
      key: 'getAllMarketsWithPanelAccount',
      staleTime: Infinity,
    },
  };

  return CoreClient.getInstance().request<
    GetAllMarketsWithPanelsSummary,
    GetAllMarketsWithPanelsSummaryVariables
  >(GET_ALL_MARKETS_WITH_PANELS_SUMMARY, args, options);
}

export function searchMarkets(params: SearchMarketsVariables) {
  const options = {
    cache: {
      key: 'searchMarkets',
      staleTime: Infinity,
    },
  };

  return CoreClient.getInstance().request<SearchMarkets, SearchMarketsVariables>(
    SEARCH_MARKETS,
    params,
    options,
  );
}

export async function aggregation() {
  const markets = await getAllMarketsWithPanelSummary({
    panelStatus: [PanelStatus.ACTIVE],
    mediaNames: aggsMediaNames,
  }).then((r) => r.searchMarkets);

  const indexedSummary = markets.data.reduce((acc, market) => {
    const {panelStatus, withPhotos, withoutPhotos, withoutRoads} = market.panelsSummary || {};
    const activePanelStatus = getActivePanelStatus(panelStatus);
    const activePanels = activePanelStatus?.grossTotal;
    const curationStatusTotal = getCurationStatusTotal(activePanelStatus?.curationStatus);
    acc[market.code] = {
      ...curationStatusTotal,
      activePanels,
      withPhotos,
      withoutPhotos,
      withoutRoads,
    };
    return acc;
  }, {} as Record<string, Record<CurationStatus, number> & {activePanels: number; withPhotos: number; withoutPhotos: number; withoutRoads: number}>);

  return {
    total: markets.total,
    data: markets.data.map((market) => ({
      ...market,
      panelsSummary: indexedSummary[market.code],
    })),
  };
}

const getCurationStatusTotal = (
  curationStatus: UnPromisify<
    ReturnType<typeof getAllMarketsWithPanelSummary>
  >['searchMarkets']['data'][number]['panelsSummary']['panelStatus'][number]['curationStatus'],
) =>
  (curationStatus || []).reduce((acc2, cs) => {
    return {...acc2, [cs.type]: cs.total};
  }, {} as Record<(typeof curationStatus)[number]['type'], (typeof curationStatus)[number]['total']>);

const getActivePanelStatus = (
  panelStatus: UnPromisify<
    ReturnType<typeof getAllMarketsWithPanelSummary>
  >['searchMarkets']['data'][number]['panelsSummary']['panelStatus'],
) => panelStatus?.find((ps) => ps.type === PanelStatus.ACTIVE);
