import BinaryOptionFilter from '../BinaryOptionFilter';

const FILTER_ID = 'acceptableImage' as const;

class AcceptableImageFilter extends BinaryOptionFilter {
  static id = FILTER_ID;

  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Acceptable Marketing Image',
    description: 'Filter by acceptable marketing image',
  };

  static defaultProps = {
    ...BinaryOptionFilter.defaultProps,
    filterAddOption: AcceptableImageFilter.defaultFilterAddOption,
  } as Partial<typeof BinaryOptionFilter.defaultProps>;
}

export default AcceptableImageFilter;
