import type {History} from 'history';

import type {RootState} from 'config/redux/rootReducer';

import {querySync} from '../../prism/lib';
import {actions} from '../actions';
import {UrlParams} from './';

export const roadSegmentsValueToString = (
  curatedRoadSegments: RootState['panels']['filters']['curatedRoadSegments'],
) => {
  if (typeof curatedRoadSegments === 'boolean') {
    return curatedRoadSegments ? 'yes' : 'no';
  }
  return '';
};

export const roadSegmentsQuerySync = (history: History<unknown>) =>
  querySync(UrlParams.curatedRoadSegments, {
    history,
    selector: ({panels: {filters}}) => filters,
    action: actions.concatFilters,
    stringToValue: (string) => ({
      curatedRoadSegments: string === 'yes' || string === 'no' ? string === 'yes' : null,
    }),
    valueToString: (value) => roadSegmentsValueToString(value.curatedRoadSegments),
    listeners: [actions.setFilters, actions.resetFilters],
    defaultValue: {
      curatedRoadSegments: null,
    },
  });
