import type {FilterColorChipProps} from '@onsmart/ui-kit';
import {FilterColorChip, filterColorChipStylesDecorator} from '@onsmart/ui-kit';
import {truncate} from 'utils/string';

interface Props extends FilterColorChipProps<string[]> {
  labelPrefix: string;
}

const trunc = (value) => truncate(String(value), 20);

class Chip extends FilterColorChip<Props, string[]> {
  getLabel(values: string[]) {
    if (values.length > 2) {
      return `${this.props.labelPrefix}: ${trunc(values[0])} and ${values.length - 1} more`;
    } else {
      return `${this.props.labelPrefix}: ${values.map(trunc).join(', ')}`;
    }
  }
}

export default filterColorChipStylesDecorator(Chip);
