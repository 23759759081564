import React from 'react';
import {BaseFilter, MultipleCheckFilterPopper} from '@onsmart/ui-kit';
import {PanelVisibility} from '__generated__/globalTypes';
import isEqual from 'lodash/isEqual';

import Chip from './Chip';

import type {BaseFilterProps} from '@onsmart/ui-kit';
type Props = BaseFilterProps<PanelVisibility[]> & {
  options?: PanelVisibility[];
};

const FILTER_ID = 'visibility' as const;

class VisibilityFilter extends BaseFilter<Props, string[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Visibility',
    description: 'Filter by visibility',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: VisibilityFilter.defaultFilterAddOption,
    options: [PanelVisibility.ROADSIDE, PanelVisibility.INDOOR, PanelVisibility.EXTERIOR],
  } as Partial<Props>;

  render() {
    const {value = [], initialValue = [], context, isOpen: open, tempValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {!!value.length && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && (
          <MultipleCheckFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}

export default VisibilityFilter;
