import { authentication } from '@onsmart/auth-client'
import { environment } from 'config/environment'

if (!environment.settings.auth.account || !environment.settings.auth.gateway) {
  throw new Error('Auth settings are not defined')
}

export const accessTokenManager = new authentication.AccessTokenManager(
  environment.settings.auth.account,
  environment.settings.auth.gateway
)