import {createReducer} from 'redux-act';
import produce from 'immer';

import {getEmptyRoad, getRoadProcessed} from 'models';

import {actions} from './actions';
import {roadReducers} from './roadReducers';
import {getClipData, produceFor} from './utils';

import type {RoadPanel, RoadProcessed} from 'models';
import type {
  ICreatePanelInput,
  IGetPanelResponse,
  IUpdatePanelInput,
} from '../../../../services/graphql/PanelService';

interface IPanelNavigation {
  [key: string]: {
    currentId: string;
    prevId?: string;
    nextId?: string;
  };
}

export interface IPanelInput extends Partial<ICreatePanelInput>, Partial<IUpdatePanelInput> {
  marketName?: string;
  mapImage?: {
    url: string;
  };
}

const empty_road = getEmptyRoad();

export const initialState = {
  panel: {} as IGetPanelResponse,
  panelInput: {} as IPanelInput,

  panelNavigationIndex: {} as IPanelNavigation,
  panelFormLoading: false,

  mapDialogOpen: false,

  curationRoadOpen: false,
  roadPanel: empty_road as RoadProcessed,
  rawRoadPanel: empty_road as RoadPanel,
  initialRoadPanel: empty_road as RoadPanel,
};

export type InitialState = typeof initialState;

// REDUCERS

export default createReducer({}, initialState)
  .on(
    actions.setPanelNavigationIndex,
    produceFor('panelNavigationIndex', (_, payload) => payload),
  )

  .on(
    actions.cleanPanelForm,
    produce((state) => {
      state.mapDialogOpen = false;
      state.panel = initialState.panel;
      state.panelInput = initialState.panelInput;
    }),
  )
  .on(
    actions.toggleMapDialog,
    produceFor('mapDialogOpen', (state) => !state),
  )

  .on(
    actions.setPanel,
    produce((state, payload) => {
      state.panel = payload;
      state.panelInput = initialState.panelInput;
      state.initialRoadPanel.id = payload.id;
      state.roadPanel.id = payload.id;
    }),
  )
  .on(
    actions.setPanelInput,
    produce((state, payload) => {
      state.panel = {...state.panel, ...(payload as IGetPanelResponse)};
      state.panelInput = {...state.panelInput, ...payload};
    }),
  )
  .on(
    actions.showPanelFormLoading,
    produceFor('panelFormLoading', () => true),
  )
  .on(
    actions.hidePanelFormLoading,
    produceFor('panelFormLoading', () => false),
  )

  .on(
    actions.setRawRoad,
    produceFor('rawRoadPanel', (_, payload) => payload),
  )

  .on(
    actions.initCurationRoad,
    produce((state, payload) => {
      state.initialRoadPanel = payload;
      state.roadPanel = getRoadProcessed(payload);
    }),
  )
  .on(
    actions.closeCurationRoad,
    produce((state) => {
      const {id} = state.roadPanel;
      state.initialRoadPanel = {...initialState.initialRoadPanel, id};
      state.roadPanel = {...initialState.roadPanel, id};
      state.curationRoadOpen = false;
    }),
  )
  .on(
    actions.setCurationRoad,
    produceFor('roadPanel', (_, payload) => getRoadProcessed(payload)),
  )
  .on(actions.updateRoadGeoJSON, produceFor('roadPanel', roadReducers.updateRoadGeoJSON))
  .on(actions.setAlertRoadPoint, produceFor('roadPanel', roadReducers.setAlertRoadPoint))
  .on(actions.removeAlertRoadPoint, produceFor('roadPanel', roadReducers.removeAlertRoadPoint))
  .on(actions.removeRoadPoint, produceFor('roadPanel', roadReducers.removeRoadPoint))
  .on(actions.reAddRoadPoint, produceFor('roadPanel', roadReducers.reAddRoadPoint))
  .on(
    actions.saveCurationRoad,
    produceFor('curationRoadOpen', (_) => false),
  )
  .on(
    actions.openCurationRoad,
    produceFor('curationRoadOpen', (_) => true),
  )
  .on(
    actions.resetAddingCurationRoad,
    produce((state) => {
      const {panel, rawRoadPanel, roadPanel} = state;

      const result = {
        ...getClipData(panel, rawRoadPanel),
        updatedAt: roadPanel.updatedAt,
      };
      result.data.features.forEach((item, i) => {
        if (item.properties.excluded) {
          delete result.data.features[i].properties.excluded;
        }
      });
      state.roadPanel = result;
    }),
  )
  .on(
    actions.resetExcludingCurationRoad,
    produce((state) => {
      const {panel, rawRoadPanel, roadPanel} = state;

      const result = {
        ...getClipData(panel, rawRoadPanel),
        updatedAt: roadPanel.updatedAt,
      };

      result.data.features.forEach((item, i) => {
        const {type, wayId} = item.properties;
        if (type === 'node' || wayId) {
          result.data.features[i].properties.excluded = true;
        }
      });

      state.roadPanel = result;
    }),
  );
