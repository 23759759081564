export function processMarketingImageUrl(marketingImageUrl: string) {
  if (!marketingImageUrl) return null;

  const imageHeight = 360;
  const url = marketingImageUrl.includes('?') 
    ? `${marketingImageUrl}&h=${imageHeight}`
    : marketingImageUrl;

  return url;
};
