import React from 'react';
import {BaseFilter, MultipleCheckFilterPopper} from '@onsmart/ui-kit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import {toUpperCase} from 'utils/string';

import Chip from './Chip';

import type {BaseFilterProps} from '@onsmart/ui-kit';
export enum DataOriginEnum {
  ims = 'IMS',
  mad = 'MAD',
}

type Props = BaseFilterProps<DataOriginEnum[]> & {
  options: DataOriginEnum[];
};
const FILTER_ID = 'dataOrigin' as const;

class DataOriginFilter extends BaseFilter<Props, DataOriginEnum[]> {
  static id = FILTER_ID;
  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Data Origin',
    description: 'Filter by origin.',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: DataOriginFilter.defaultFilterAddOption,
    options: [DataOriginEnum.ims, DataOriginEnum.mad],
  } as Partial<Props>;

  render() {
    const {value = [], context, isOpen: open, tempValue, initialValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;

    return (
      <div>
        {!isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
            templateLabel={toUpperCase}
          />
        )}
        {open && (
          <MultipleCheckFilterPopper
            templateLabel={toUpperCase}
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || value}
            hasChanges={!!tempValue}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={this.onChange}
          />
        )}
      </div>
    );
  }
}

export default DataOriginFilter;
