import BinaryOptionFilter from '../BinaryOptionFilter';

const FILTER_ID = 'curatedRoadSegments' as const;

class RoadSegmentsFilter extends BinaryOptionFilter {
  static id = FILTER_ID;

  static defaultFilterAddOption = {
    id: FILTER_ID,
    title: 'Road Segments',
    description: 'Filter by curated road segments',
  };

  static defaultProps = {
    ...BinaryOptionFilter.defaultProps,
    filterAddOption: RoadSegmentsFilter.defaultFilterAddOption,
  } as Partial<typeof BinaryOptionFilter.defaultProps>;
}

export default RoadSegmentsFilter;
