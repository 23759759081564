import React from 'react';
import {BaseFilter, RadioFilterPopper} from '@onsmart/ui-kit';
import isEqual from 'lodash/isEqual';

import Chip from './Chip';

import type {BaseFilterProps} from '@onsmart/ui-kit';
export enum BinaryOptions {
  yes = 'yes',
  no = 'no',
}

type Props = BaseFilterProps<boolean> & {
  options: BinaryOptions[];
};

class BinaryOptionFilter extends BaseFilter<Props, boolean> {
  static defaultFilterAddOption = {
    id: 'id',
    title: 'Binary Options',
    description: 'Filter by Binary Options',
  };

  static defaultProps = {
    ...BaseFilter.defaultProps,
    filterAddOption: BinaryOptionFilter.defaultFilterAddOption,
    options: [BinaryOptions.yes, BinaryOptions.no],
  } as Partial<Props>;

  valueToOption = (value?: boolean) => {
    if (typeof value !== 'boolean') {
      return '';
    }
    if (value) {
      return BinaryOptions.yes;
    } else {
      return BinaryOptions.no;
    }
  };

  render() {
    const {value, context, isOpen: open, tempValue, initialValue, resetTempValue} = this;
    const {disabled, anchorEl} = context;
    const selectedOption = this.valueToOption(value);

    return (
      <div>
        {typeof value === 'boolean' && (
          <Chip
            chipPrefix={this.props.filterAddOption.title + ': '}
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={!disabled}
            value={selectedOption}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {open && (
          <RadioFilterPopper
            headerTitle={this.props.filterAddOption.title}
            options={this.props.options}
            open={open}
            anchorEl={anchorEl}
            value={tempValue || selectedOption}
            hasChanges={!!tempValue && tempValue !== selectedOption}
            onPopperClose={this.closePopper}
            resetTempValue={resetTempValue}
            onChange={(value, event) => this.onChange(value === BinaryOptions.yes, event)}
          />
        )}
      </div>
    );
  }
}

export default BinaryOptionFilter;
