import React from 'react';
import {BaseFilter} from '@onsmart/ui-kit';
import _isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Chip from './Chip';
import {Popper} from './Popper';

import type {BaseFilterProps} from '@onsmart/ui-kit';
import type {MediaFilter} from 'models/MediaType';
type Props = BaseFilterProps<MediaFilter>;
const FILTER_ID = 'media' as const;

class MediaTypeFilter extends BaseFilter<Props, MediaFilter> {
  static id: typeof FILTER_ID = FILTER_ID;

  static defaultProps: Partial<Props> = {
    ...BaseFilter.defaultProps,
    filterAddOption: {
      id: FILTER_ID,
      title: 'Media Type',
      description: 'Filter by category, subcategory and media type',
    },
  };

  shouldCancelFilterOnPopperClose(): boolean {
    const {value} = this;
    return _isEmpty(value);
  }

  handleSetTempValue = (value: Partial<MediaFilter>) => {
    this.context.handleSetTempValue(FILTER_ID, value);
  };

  render() {
    const {value, initialValue, isOpen, context, resetTempValue, tempValue} = this;
    const {anchorEl, disabled} = context;

    const isLoaded = !disabled;

    return (
      <div>
        {!_isEmpty(value) && (
          <Chip
            handleClick={this.openPopper}
            onReset={this.onReset}
            isLoaded={isLoaded}
            value={value}
            enableColor={this.hasInitialFilters}
            hasChanges={!isEqual(value, initialValue)}
          />
        )}
        {isOpen && (
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            value={tempValue || value || ({} as MediaFilter)}
            onPopperClose={this.closePopper}
            onChange={this.onChange}
            hasChanges={!!tempValue}
            resetTempValue={resetTempValue}
          />
        )}
      </div>
    );
  }
}

export default MediaTypeFilter;
